$mainRed:#ea583f;
$mainBlue: #154fa0;
$lightBlue: #d3e6f7;
$pagePadding: 25px;
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop
$min_width: 1920px;
$max_width: 3000px;
$min_font: 18px;
$max_font: 25px;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
@function em($px, $base: $base-font-size) {
  @return ($px / $base) * 1em;
}
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@keyframes scroll {
  0% {
    opacity: 0;
    transform: translate(-50%, 0);
  }
  10% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 10px);
    opacity: 0;
  }
}

@keyframes scroll-arrow {
  0% {
    opacity: 0;
    transform: translate(-50%, 0) rotate(45deg);
  }
  10% {
    transform: translate(-50%, 0) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 5px) rotate(45deg);
    opacity: 0;
  }
}

@keyframes chevron {
  0% {
    opacity: 0;
    transform: translate(0%, 0%) rotate(135deg);
  }
  10% {
    transform: translate(0%, 0%) rotate(135deg);
    opacity: 1;
  }
  100% {
    transform: translate(10px, 0%) rotate(135deg);
    opacity: 0;
  }
}

@keyframes chevron-reversed {
  0% {
    opacity: 0;
    transform: translate(0%, 0%) rotate(-45deg);
  }
  10% {
    transform: translate(0%, 0%) rotate(-45deg);
    opacity: 1;
  }
  100% {
    transform: translate(-10px, 0%) rotate(-45deg);
    opacity: 0;
  }
}

html {
  box-sizing: border-box;
  background: white;

  height: 100%;
  font-size: 16px;
  // @include fluid-type($min_width, $max_width, $max_font, $min_font);
  // padding: 0 2.063em;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body,
#root,
.app-wrapper {
  color: $mainBlue;
  min-width: 320px;
  font-family: "Open Sans", sans-serif;
  min-height: 100%;
}

html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
}

.heading {
  // opacity:0;
  font-size: 3.125em;
  // font-size: 9.26vw;

  line-height: 1.1;
  margin: 0;
  padding: 0;
  color: $lightBlue;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  margin-bottom: 1.5rem;
  margin-left: $pagePadding;
  padding-left: 15px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 1.625rem;
    left: 0;
    transform: translate(-100%, -50%);
    height: 2px;
    width: $pagePadding;
    background: $lightBlue;
  }
}
.subheading {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.875em;
  line-height: 1.1;
  margin: 0;
  padding: 0;
}

.paragraph {
  font-size: 1em;
  padding: 0;
  line-height: 1.2;
  margin: 0;

  color: $mainBlue;
  margin-bottom: 1.75rem;
}

header,
footer {
  font-size: 1.875rem;

  padding: 0 $pagePadding;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // height: 6.875rem;
  height: 110px;

  .logo-wrapper {
    flex: 0 0 40%;
    max-width: 225px;
    min-width: 180px;
    img {
      width: 100%;
    }
  }

  .main-menu-wrapper {
    font-size: 1em;
    flex: 0 0 auto;
    margin-bottom: 40px;
    &.hamb-wrapper {
      margin-bottom: 0;
      display: flex;

      .hamb-menu-wrapper {
        font-size: 1em;
        z-index: 1;
        padding: 0 $pagePadding;
        // transition: all 0.3s ease;
        position: fixed;
        width: 100vw;
        height: calc(100vh - 110px);
        background: $mainBlue;
        left: 0;
        top: 110px;
        overflow-y: auto;
        padding-bottom: 40px;

        .main-menu {
          font-size: 1em;
          color: white;
          flex-direction: column;
          padding: $pagePadding 0;
          justify-content: flex-start;
          font-weight: 800;
          height: 100%;
          width: 100%;
          li {
            letter-spacing: 0;
            padding-left: 20px;
            font-size: 1em;
            margin-bottom: 0.733em;

            span {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                width: 0.333em;
                height: 0.333em;
                border-radius: 50%;
                background: $mainRed;
                top: 50%;
                left: -20px;
                transform: translate(0, -50%);
              }
            }
            &.active {
              &:before {
                display: none;
              }
            }

            ul {
              margin-top: 0.733em;
              li {
                padding-left: 30px;
                font-weight: 400;
                margin-bottom: 0.75em;
                span {
                  &:after {
                    content: "";
                    position: absolute;
                    width: 0.467em;
                    height: 0.1em;
                    border-radius: 0;
                    background: $mainRed;

                    top: 0.667em;
                    left: -20px;
                    transform: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .main-menu {
      font-weight: 600;
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      color: $mainBlue;
      li {
        position: relative;
        transition: all 0.3s ease;
        margin-right: 0.868vw;
        // font-size: 1.111vw;
        text-transform: capitalize;
        // letter-spacing: 0.2em;
        button {
          text-transform: inherit;
          letter-spacing: inherit;
        }
        &:before {
          transition: all 0.3s ease;
          content: "";
          position: absolute;
          width: 0;
          height: 3px;
          background: $mainRed;
          left: 0;
          bottom: -5px;
        }

        &.active {
          transition: all 0.3s ease;
          > button {
            span {
              text-shadow: 0 0 0 $mainBlue, 0 0 0 $mainBlue;
            }
          }

          &:before {
            transition: all 0.3s ease;
            width: 25px;
          }
        }

        &.reversed {
          &:before {
            transition: all 0.3s ease;
            width: 3px;
            height: 0px;
            bottom: 3px;
            left: 10px;
            transform: translate(0%, 100%);
          }
          &.active {
            transition: all 0.3s ease;
            text-shadow: 0 0 0 $mainBlue, 0 0 0 $mainBlue;
            &:before {
              transition: all 0.3s ease;
              height: 14px;
            }
          }
        }
      }
    }
  }
}

.text-red {
  color: $mainRed;
}
.font-weight-800 {
  font-weight: 800;
}

header {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
}
footer {
  // border:1px solid red;
  height: auto;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  color: $mainBlue;
  font-size: 1.25em;
  flex-direction: column-reverse;

  .footer-tel-wrapper {
    display: flex;
    font-size: 1em;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em 0;
    color: white;
    // width: calc(100% + (#{$pagePadding}*2));
    width: 100%;
    text-align: center;
    // margin-left: -$pagePadding;
    // margin-top: 40px;
    // margin-right: auto;
    background: $mainBlue;

    a {
      font-weight: 800;
      font-size: 1em;
      padding-left: 0.2em;
      span {
        color: $mainRed;
      }
    }
  }

  .scroll-animation-wrapper {
    // flex: 0 0 2.292vw;
    max-width: 33px;
    width: 2.292vw;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    .mouse {
      position: relative;
      width: 100%;
      // height: 3.924vw;
      height: 55px;
      border: 2px solid $mainBlue;
      border-radius: 25px;
      &-ball {
        position: absolute;
        width: 20%;
        left: 50%;
        top: 20%;

        border-radius: 50%;
        background-color: $mainBlue;
        transform: translate(-50%, 0);
        animation-name: scroll;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
        animation-iteration-count: infinite;
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
      &-arrow {
        position: relative;
        left: 50%;
        width: 30%;
        border: 2px solid $mainBlue;
        transform: translate(-50%, 0) rotate(45deg);
        border-top-width: 0;
        border-left-width: 0;
        animation-name: scroll-arrow;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
        animation-iteration-count: infinite;
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
  }
  .social-media-wrapper {
    // margin-top: 40px;
    padding: 0 0 0 $pagePadding;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    // margin-left: auto;
    width: 70%;
    margin-right: auto;
    span {
      margin-bottom: 1em;
    }
    ul {
      width: 100%;
      // margin-left: 4px;
      margin-bottom: 1em;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      li {
        width: 4.25em;
        margin-right: 0.25em;

        &:last-of-type {
          margin-right: 0;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
  &:hover,
  &:visited,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}
img {
  width: 100%;
}
button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: transparent;
  color: inherit;
  border: none;
  &:hover,
  &:visited,
  &:focus {
    color: inherit;
    border: none;
    outline: none;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-wrapper {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;

  //  > .swiper-container{
  //     width: 100%;
  //     height: 100%;
  //     margin-top: 110px;
  //     overflow: visible;
  //     > .swiper-wrapper {
  //       flex-direction: column;
  //     }
  //   }
}

.hamb {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
  position: relative;
  z-index: 101;
  width: 1.833em;
  height: 1.583em;
  max-height: 48px;
  max-width: 55px;

  div {
    position: absolute;
    width: 100%;
    height: 0.1em;
    min-height: 2px;
    background: $mainBlue;
    transition: all 0.3s ease;
    &:nth-of-type(1) {
      left: 0;
      top: 0;
    }
    &:nth-of-type(2) {
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
    &:nth-of-type(3) {
      left: 0;
      top: 100%;
      transform: translate(0, -100%);
    }
  }
  &.open {
    div {
      &:nth-of-type(1) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(135deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-135deg);
      }
    }
  }
}

.swiper-container {
  width: 100%;
  // height: 100%;
}
.single-slide {
  // width: 100%;
  // height: 100%;
  // font-size: 16px;
}

section {
  margin-bottom: 2.75em;
  // height:auto!important;
  &.homepage {
    .content-wrapper {
      .heading {
        position: absolute;
        top: 33%;
        left: 0;
      }
      .image-wrapper {
        &:after {
          padding-bottom: 51.85%;
        }
      }

      .paragraph {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &.about {
    // &.hidden {
    //   * {
    //     opacity: 0;
    //   }
    // }

    .content-wrapper {
      .image-wrapper {
        margin-bottom: 0;
        &:after {
          padding-bottom: 100%;
        }
      }
    }
  }
  &.offer {
    .content-wrapper {
      .image-wrapper {
        margin-bottom: 0;
        &:after {
          padding-bottom: 78%;
        }
      }
    }
  }

  &.contact {
    .content-wrapper {
      .contact-wrapper {
        padding: 0 $pagePadding;
        font-size: 1.563rem;
        margin: 1rem 0;
        img {
          max-width: 16px;

          margin-right: 15px;
        }
        a {
          display: block;
        }
      }
      .image-wrapper {
        margin: 0 $pagePadding * 2;
        &:after {
          content: "";
          display: block;
          padding-bottom: 31%;
        }
      }
    }
  }
  &.subpage {
    .content-wrapper {
      .image-wrapper {
        margin-top: 1.75rem;
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
      .subheading {
        padding: 0 $pagePadding;
        margin-bottom: 1.75rem;
      }
      ul {
        margin: 0;
        padding: 0 $pagePadding;
        margin-bottom: 1.75rem;

        li {
          line-height: 1.2;
          span {
            position: relative;
            &:first-of-type {
              padding-left: $pagePadding;
              &:after {
                content: "";
                position: absolute;
                width: 0.333em;
                height: 0.333em;
                border-radius: 50%;
                background: $mainRed;
                top: 50%;
                left: 0px;
                transform: translate(0, -50%);
              }
            }
          }
        }
      }
      .swiper-pagination {
        bottom: 0;
      }
    }

    .swiper-wrapper {
      margin-bottom: 10px;
    }
  }
  .content-wrapper {
    .image-wrapper {
      margin-bottom: 1.75rem;
      position: relative;
      &:after {
        content: "";
        display: block;
        padding-bottom: 51.85%;
      }
    }
    .partner-wrapper {
      padding: 0 $pagePadding;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: 85%;
      margin-bottom: 80px;
      .partner {
        flex: 0 0 29.3333%;
        max-width: 29.3333%;
        transition: all 0.3s ease;
        padding-right: 15px;
        // opacity: 0.5;
        &:hover {
          // opacity: 1;
          transition: all 0.3s ease;
        }
        img {
          width: 100%;
          max-width: 100px;
        }
      }
    }
    .offer-wrapper {
      padding: 0 $pagePadding;
      li {
        margin-bottom: 1.25em;
        .paragraph {
          padding: 1.25em 0;
        }
        .subheading {
          // cursor: pointer;
        }
      }
    }
    .paragraph {
      padding: 0 $pagePadding;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .col {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  &.gallery,
  &.certificates {
    .swiper-container {
      padding: $pagePadding 0;
      @media (min-width: 1200px) {
        width: 50%;
      }
      .swiper-wrapper {
      }
      &:nth-of-type(2) {
        // display: none;
      }
      .swiper-slide {
        position: relative;
        // &:after {
        //   content: "";
        //   display: block;
        //   padding-bottom: 66%;
        // }
        img {
          width: 100%;
        }
      }
      &:nth-of-type(1) {
        .swiper-slide {
          &.mobile {
            width: 65% !important;
          }
          align-self: center;
          &.swiper-slide-active {
            // transition: all 1s ease;
            // box-shadow: 0px 0px 12px 0px $mainBlue;
            border:2px solid $mainRed;
          }
        }
      }
    }
  }
}
.gallery-wrapper {
  // padding: 0 $pagePadding;

  .swiper-container {
    padding: $pagePadding 0;
  }
}
.gallery-thumbs-wrapper {
  width: 50%;
  margin: auto;
  display: none;
  .swiper-slide {
    pointer-events: auto !important;
  }
  img {
    width: 100%;
  }
}

.swiper-container {
  width: 100%;
  // // height: 2000px;
  // // height: 100%!important;
  // max-height: 100%;
  // margin-top: 110px;
}

.button {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  border: 2px solid $mainRed;
  // line-height: 0.75;
  padding: 1.25rem;
  border-radius: 3.125rem;
  white-space: nowrap;

  display: flex;
  align-items: center;

  // height: 4.250em;
  &:hover {
    border: 2px solid $mainRed;
    .button-chevron {
      animation-play-state: paused;
      animation-name: none;
    }
  }
}
.button-chevron {
  border: 2px solid $mainBlue;
  width: 0.45rem;
  border-right-width: 0;
  border-bottom-width: 0;
  margin-left: 0.25rem;
  transform: rotate(135deg);
  animation-name: chevron;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &.reversed {
    transform: rotate(-45deg);
    animation-name: chevron-reversed;
  }
}

.floating-icon {
  position: fixed;
  bottom: $pagePadding;
  right: $pagePadding;
  width: 4.25em;
  height: 4.25em;
  border-radius: 50%;
  border: 5px solid $mainBlue;
  background: white;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  z-index: 1000;
  button {
    width: 100%;
    height: 100%;
  }
  img,
  svg {
    width: 60%;
    fill: $mainBlue;
  }
}

@media (max-width: 480px) {
  header {
    .main-menu-wrapper {
      &.hamb-wrapper {
        .hamb-menu-wrapper {
          font-size: 5.5vw;
        }
      }
    }
  }

  .hamb {
    width: 10vw;
    height: 8.8vw;
    max-height: 48px;
    max-width: 55px;
  }

  .heading {
    font-size: 9.26vw;
    margin-bottom: 5.5vw;

    &:before {
      content: "";

      top: 5vw;
    }
  }
  .subheading {
    font-size: 5.5vw;
  }
  ul {
    font-size: 4vw;
  }
  .paragraph {
    font-size: 4vw;
    margin-bottom: 5.5vw;
  }

  section {
    margin-bottom: 80px;

    .content-wrapper {
      .offer-wrapper {
        li {
        }
      }
    }
    &.subpage {
      .content-wrapper {
        .subheading {
          margin-bottom: 4vw;
        }
        ul {
          li {
            span {
              &:first-of-type {
                padding-left: 4.6vw;
              }
            }
          }
        }
      }
    }

    &.contact {
      .content-wrapper {
        .contact-wrapper {
          font-size: 4.6vw;
        }
      }
    }
  }
  footer {
    font-size: 4vw;
  }

  .button {
    font-size: 4vw;
    padding: 4.63vw;
  }
}

@media (min-width: 1200px) {
  .left-animation,
  .right-animation {
    opacity: 0;
  }
  header,
  footer {
    height: 110px;
    font-size: 1rem;
    .main-menu-wrapper {
      .main-menu {
        li {
          ul {
            display: flex;
            position: absolute;
            top: 0;

            flex-direction: row;
            left: 50%;

            white-space: nowrap;
            transform: translate(-50%, 30px);
          }
        }
      }
    }
  }

  footer {
    padding: 0 $pagePadding;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    .footer-tel-wrapper {
      width: auto;
      background: white;
      color: $mainBlue;
    }
    .social-media-wrapper {
      span {
        margin-right: 10px;
      }
      width: auto;
      flex-wrap: nowrap;
      margin: 0;
      ul {
        width: auto;
        li {
          max-width: 24px;
        }
      }
    }
  }
  .bar-1,
  .bar-2 {
    position: absolute;
    width: 0%;
    top: 0%;
    height: 100%;
    // z-index: -1;
    background: $lightBlue;
  }
  .heading {
    position: relative;
    margin-top: 2.219rem;
    display: inline-block;
  }
  .app-wrapper {
    display: flex;
    flex-direction: column;
    // position: fixed;
    // top:0;
    // left:0;
    height: 100vh;
    > .swiper-container {
      // padding: 0 $pagePadding;
      margin-top: 110px;
      height: calc(100% - 110px) !important;
    }
  }
  section {
    overflow: hidden;
    .row {
      height: 100% !important;
      width: calc(100% - 8vw) !important;
      margin: 0 auto;
    }
    margin-bottom: 0;
    .heading {
      &::before {
        width: calc(25px + 4vw);
      }
    }
    .content-wrapper {
      height: 100%;

      .image-wrapper {
        height: 100%;
        margin-bottom: 0;

        // &::after{
        //   display: none;
        // }
      }

      .partner-wrapper {
        max-width: 70%;
        .partner {
          transition: all 0.3s ease;
          opacity: 0.5;
          &:hover {
            opacity: 1;
            transition: all 0.3s ease;
          }
        }
      }
    }

    &.about,
    &.offer {
      .content-wrapper {
        .image-wrapper{
          max-height: 80%;
        }
      }
      .left-animation {
        padding-right: 15%;
      }
    }
    &.homepage {
      width: 100% !important;
      .content-wrapper {
        .wrapper {
          width: 45%;
          // opacity: 1;
          position: absolute;
          left: 4vw;
          top:0;
          .heading {
            position: relative;
            left: auto;
            top: auto;
          }
          .paragraph {
            margin-left: $pagePadding * 3;
            color: white;
          }
        }
      }
    }
    &.gallery,
    &.certificates {
      width: 100% !important;

      .heading {
        position: absolute;
        z-index: 10;
        left: 4vw;
      }
      .content-wrapper {
        .gallery-wrapper {
          height: 85%;
          .swiper-slide {
            &.swiper-slide-active {
              border: none;
            }
            
          }
        }
        .swiper-container {
          height: 100%;
          padding: 0;
        }
        .swiper-slide {
          height: 100%;
          &.swiper-slide-active {
            // box-shadow: none;
            border: none;
          }
          &.swiper-slide-thumb-active {
            // transition: all 1s ease;
            // box-shadow: 0px 0px 12px 0px $mainBlue;
            border:2px solid $mainRed;
          }
        }
        .gallery-thumbs-wrapper {
          height: 15%;
          //  margin: 10px 0;
          width: 100%;
          .swiper-container {
            width: 50%;
            // padding: 10px 0;
            .swiper-slide {
              height: 80%;
            }
          }
        }

        // .image-wrapper{
        // height: 100%;
        // }
      }
    }
    &.certificates {
      .swiper-container {
        .swiper-slide {
          text-align: center;
          img {
            width: auto;
            height: 100%;
          }
        }
      }

      .gallery-thumbs-wrapper {
        height: 10%;
        .swiper-container {
          .swiper-slide {
            //  width:auto!important;
          }
        }
      }
    }
    &.subpage {
      .content-wrapper {
        .subheading {
          padding-left: 0;
        }
        .image-wrapper {
          margin-top: 0;
          max-width: 80%;
          margin: 0 auto;
        }
      }
      .left-animation {
        padding-left: 5rem;
      }
      .swiper-container {
        height: 100%;
      }
    }
  }
  .gallery-thumbs-wrapper {
    display: block;
  }

  .swiper-slide-active {
    z-index: 10;
  }

  .button {
    padding: 1rem;
    font-size: 0.8rem;
  }
}

@media (min-width: 1920px) {
  html {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
  }
  header {
    .main-menu-wrapper {
      .main-menu {
        li {
          ul {
            top: 5px;
          }
        }
      }
    }
  }
}
.swiper-pagination,
.swiper-pagination-nested {
  &:after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: 1px;
    height: 80%;
    transform: translate(-50%, 100%);
    background: $mainBlue;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .swiper-pagination-bullet {
    outline: none;
    border: 1px solid $mainBlue;
    border-radius: 50%;
    background: transparent;
    opacity: 1;
    width: 14px;
    position: relative;
    height: 14px;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;
      transform: translate(-50%, -50%) scale(0);
      background: #ef7544;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      border-radius: 50%;
    }
    &-active {
      &:after {
        transform: translate(-50%, -50%) scale(1);
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
  }
}
.swiper-pagination-nested {
  position: absolute;
  text-align: center;
  z-index: 100;
  width: auto !important;
  left: 50% !important;
  transform: translate(-50%, 0);
  &:after {
    display: none;
  }
}

.loading-screen {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // background: white;
  background: #d2e5f6;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  svg {
    width: 100%;
  }
}

.offer-header-wrapper {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  .arrow {
    transform: rotate(180deg);
    transition: all 0.3s ease;
    width: 100%;
    max-width: 20px;
    &.rotated {
      transform: rotate(0deg);
      transition: all 0.3s ease;
    }
  }
}

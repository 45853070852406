body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 0;
    transform: translate(-50%, 0); }
  10% {
    transform: translate(-50%, 0);
    opacity: 1; }
  100% {
    transform: translate(-50%, 10px);
    opacity: 0; } }

@keyframes scroll {
  0% {
    opacity: 0;
    transform: translate(-50%, 0); }
  10% {
    transform: translate(-50%, 0);
    opacity: 1; }
  100% {
    transform: translate(-50%, 10px);
    opacity: 0; } }

@-webkit-keyframes scroll-arrow {
  0% {
    opacity: 0;
    transform: translate(-50%, 0) rotate(45deg); }
  10% {
    transform: translate(-50%, 0) rotate(45deg);
    opacity: 1; }
  100% {
    transform: translate(-50%, 5px) rotate(45deg);
    opacity: 0; } }

@keyframes scroll-arrow {
  0% {
    opacity: 0;
    transform: translate(-50%, 0) rotate(45deg); }
  10% {
    transform: translate(-50%, 0) rotate(45deg);
    opacity: 1; }
  100% {
    transform: translate(-50%, 5px) rotate(45deg);
    opacity: 0; } }

@-webkit-keyframes chevron {
  0% {
    opacity: 0;
    transform: translate(0%, 0%) rotate(135deg); }
  10% {
    transform: translate(0%, 0%) rotate(135deg);
    opacity: 1; }
  100% {
    transform: translate(10px, 0%) rotate(135deg);
    opacity: 0; } }

@keyframes chevron {
  0% {
    opacity: 0;
    transform: translate(0%, 0%) rotate(135deg); }
  10% {
    transform: translate(0%, 0%) rotate(135deg);
    opacity: 1; }
  100% {
    transform: translate(10px, 0%) rotate(135deg);
    opacity: 0; } }

@-webkit-keyframes chevron-reversed {
  0% {
    opacity: 0;
    transform: translate(0%, 0%) rotate(-45deg); }
  10% {
    transform: translate(0%, 0%) rotate(-45deg);
    opacity: 1; }
  100% {
    transform: translate(-10px, 0%) rotate(-45deg);
    opacity: 0; } }

@keyframes chevron-reversed {
  0% {
    opacity: 0;
    transform: translate(0%, 0%) rotate(-45deg); }
  10% {
    transform: translate(0%, 0%) rotate(-45deg);
    opacity: 1; }
  100% {
    transform: translate(-10px, 0%) rotate(-45deg);
    opacity: 0; } }

html {
  box-sizing: border-box;
  background: white;
  height: 100%;
  font-size: 16px; }

*,
*:before,
*:after {
  box-sizing: inherit; }

html,
body,
#root,
.app-wrapper {
  color: #154fa0;
  min-width: 320px;
  font-family: "Open Sans", sans-serif;
  min-height: 100%; }

html {
  height: 100%;
  overflow: auto; }

body {
  height: 100%; }

.heading {
  font-size: 3.125em;
  line-height: 1.1;
  margin: 0;
  padding: 0;
  color: #d3e6f7;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  margin-bottom: 1.5rem;
  margin-left: 25px;
  padding-left: 15px;
  position: relative; }
  .heading:before {
    content: "";
    position: absolute;
    top: 1.625rem;
    left: 0;
    transform: translate(-100%, -50%);
    height: 2px;
    width: 25px;
    background: #d3e6f7; }

.subheading {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.875em;
  line-height: 1.1;
  margin: 0;
  padding: 0; }

.paragraph {
  font-size: 1em;
  padding: 0;
  line-height: 1.2;
  margin: 0;
  color: #154fa0;
  margin-bottom: 1.75rem; }

header,
footer {
  font-size: 1.875rem;
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 110px; }
  header .logo-wrapper,
  footer .logo-wrapper {
    flex: 0 0 40%;
    max-width: 225px;
    min-width: 180px; }
    header .logo-wrapper img,
    footer .logo-wrapper img {
      width: 100%; }
  header .main-menu-wrapper,
  footer .main-menu-wrapper {
    font-size: 1em;
    flex: 0 0 auto;
    margin-bottom: 40px; }
    header .main-menu-wrapper.hamb-wrapper,
    footer .main-menu-wrapper.hamb-wrapper {
      margin-bottom: 0;
      display: flex; }
      header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper,
      footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper {
        font-size: 1em;
        z-index: 1;
        padding: 0 25px;
        position: fixed;
        width: 100vw;
        height: calc(100vh - 110px);
        background: #154fa0;
        left: 0;
        top: 110px;
        overflow-y: auto;
        padding-bottom: 40px; }
        header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu,
        footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu {
          font-size: 1em;
          color: white;
          flex-direction: column;
          padding: 25px 0;
          justify-content: flex-start;
          font-weight: 800;
          height: 100%;
          width: 100%; }
          header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li,
          footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li {
            letter-spacing: 0;
            padding-left: 20px;
            font-size: 1em;
            margin-bottom: 0.733em; }
            header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li span,
            footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li span {
              position: relative; }
              header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li span:after,
              footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li span:after {
                content: "";
                position: absolute;
                width: 0.333em;
                height: 0.333em;
                border-radius: 50%;
                background: #ea583f;
                top: 50%;
                left: -20px;
                transform: translate(0, -50%); }
            header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li.active:before,
            footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li.active:before {
              display: none; }
            header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li ul,
            footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li ul {
              margin-top: 0.733em; }
              header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li ul li,
              footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li ul li {
                padding-left: 30px;
                font-weight: 400;
                margin-bottom: 0.75em; }
                header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li ul li span:after,
                footer .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper .main-menu li ul li span:after {
                  content: "";
                  position: absolute;
                  width: 0.467em;
                  height: 0.1em;
                  border-radius: 0;
                  background: #ea583f;
                  top: 0.667em;
                  left: -20px;
                  transform: none; }
    header .main-menu-wrapper .main-menu,
    footer .main-menu-wrapper .main-menu {
      font-weight: 600;
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      color: #154fa0; }
      header .main-menu-wrapper .main-menu li,
      footer .main-menu-wrapper .main-menu li {
        position: relative;
        transition: all 0.3s ease;
        margin-right: 0.868vw;
        text-transform: capitalize; }
        header .main-menu-wrapper .main-menu li button,
        footer .main-menu-wrapper .main-menu li button {
          text-transform: inherit;
          letter-spacing: inherit; }
        header .main-menu-wrapper .main-menu li:before,
        footer .main-menu-wrapper .main-menu li:before {
          transition: all 0.3s ease;
          content: "";
          position: absolute;
          width: 0;
          height: 3px;
          background: #ea583f;
          left: 0;
          bottom: -5px; }
        header .main-menu-wrapper .main-menu li.active,
        footer .main-menu-wrapper .main-menu li.active {
          transition: all 0.3s ease; }
          header .main-menu-wrapper .main-menu li.active > button span,
          footer .main-menu-wrapper .main-menu li.active > button span {
            text-shadow: 0 0 0 #154fa0, 0 0 0 #154fa0; }
          header .main-menu-wrapper .main-menu li.active:before,
          footer .main-menu-wrapper .main-menu li.active:before {
            transition: all 0.3s ease;
            width: 25px; }
        header .main-menu-wrapper .main-menu li.reversed:before,
        footer .main-menu-wrapper .main-menu li.reversed:before {
          transition: all 0.3s ease;
          width: 3px;
          height: 0px;
          bottom: 3px;
          left: 10px;
          transform: translate(0%, 100%); }
        header .main-menu-wrapper .main-menu li.reversed.active,
        footer .main-menu-wrapper .main-menu li.reversed.active {
          transition: all 0.3s ease;
          text-shadow: 0 0 0 #154fa0, 0 0 0 #154fa0; }
          header .main-menu-wrapper .main-menu li.reversed.active:before,
          footer .main-menu-wrapper .main-menu li.reversed.active:before {
            transition: all 0.3s ease;
            height: 14px; }

.text-red {
  color: #ea583f; }

.font-weight-800 {
  font-weight: 800; }

header {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white; }

footer {
  height: auto;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  color: #154fa0;
  font-size: 1.25em;
  flex-direction: column-reverse; }
  footer .footer-tel-wrapper {
    display: flex;
    font-size: 1em;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em 0;
    color: white;
    width: 100%;
    text-align: center;
    background: #154fa0; }
    footer .footer-tel-wrapper a {
      font-weight: 800;
      font-size: 1em;
      padding-left: 0.2em; }
      footer .footer-tel-wrapper a span {
        color: #ea583f; }
  footer .scroll-animation-wrapper {
    max-width: 33px;
    width: 2.292vw;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0); }
    footer .scroll-animation-wrapper .mouse {
      position: relative;
      width: 100%;
      height: 55px;
      border: 2px solid #154fa0;
      border-radius: 25px; }
      footer .scroll-animation-wrapper .mouse-ball {
        position: absolute;
        width: 20%;
        left: 50%;
        top: 20%;
        border-radius: 50%;
        background-color: #154fa0;
        transform: translate(-50%, 0);
        -webkit-animation-name: scroll;
                animation-name: scroll;
        -webkit-animation-duration: 2.2s;
                animation-duration: 2.2s;
        -webkit-animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
                animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite; }
        footer .scroll-animation-wrapper .mouse-ball:after {
          content: "";
          display: block;
          padding-bottom: 100%; }
      footer .scroll-animation-wrapper .mouse-arrow {
        position: relative;
        left: 50%;
        width: 30%;
        border: 2px solid #154fa0;
        transform: translate(-50%, 0) rotate(45deg);
        border-top-width: 0;
        border-left-width: 0;
        -webkit-animation-name: scroll-arrow;
                animation-name: scroll-arrow;
        -webkit-animation-duration: 2.2s;
                animation-duration: 2.2s;
        -webkit-animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
                animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite; }
        footer .scroll-animation-wrapper .mouse-arrow:after {
          content: "";
          display: block;
          padding-bottom: 100%; }
  footer .social-media-wrapper {
    padding: 0 0 0 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    margin-right: auto; }
    footer .social-media-wrapper span {
      margin-bottom: 1em; }
    footer .social-media-wrapper ul {
      width: 100%;
      margin-bottom: 1em;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start; }
      footer .social-media-wrapper ul li {
        width: 4.25em;
        margin-right: 0.25em; }
        footer .social-media-wrapper ul li:last-of-type {
          margin-right: 0; }
        footer .social-media-wrapper ul li img {
          width: 100%; }

a {
  text-decoration: none;
  color: inherit; }
  a:hover, a:visited, a:focus {
    color: inherit;
    text-decoration: none; }

img {
  width: 100%; }

button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: transparent;
  color: inherit;
  border: none; }
  button:hover, button:visited, button:focus {
    color: inherit;
    border: none;
    outline: none; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.hamb {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
  position: relative;
  z-index: 101;
  width: 1.833em;
  height: 1.583em;
  max-height: 48px;
  max-width: 55px; }
  .hamb div {
    position: absolute;
    width: 100%;
    height: 0.1em;
    min-height: 2px;
    background: #154fa0;
    transition: all 0.3s ease; }
    .hamb div:nth-of-type(1) {
      left: 0;
      top: 0; }
    .hamb div:nth-of-type(2) {
      left: 0;
      top: 50%;
      transform: translate(0, -50%); }
    .hamb div:nth-of-type(3) {
      left: 0;
      top: 100%;
      transform: translate(0, -100%); }
  .hamb.open div:nth-of-type(1) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg); }
  .hamb.open div:nth-of-type(2) {
    opacity: 0; }
  .hamb.open div:nth-of-type(3) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-135deg); }

.swiper-container {
  width: 100%; }

section {
  margin-bottom: 2.75em; }
  section.homepage .content-wrapper .heading {
    position: absolute;
    top: 33%;
    left: 0; }
  section.homepage .content-wrapper .image-wrapper:after {
    padding-bottom: 51.85%; }
  section.homepage .content-wrapper .paragraph:last-of-type {
    margin-bottom: 0; }
  section.about .content-wrapper .image-wrapper {
    margin-bottom: 0; }
    section.about .content-wrapper .image-wrapper:after {
      padding-bottom: 100%; }
  section.offer .content-wrapper .image-wrapper {
    margin-bottom: 0; }
    section.offer .content-wrapper .image-wrapper:after {
      padding-bottom: 78%; }
  section.contact .content-wrapper .contact-wrapper {
    padding: 0 25px;
    font-size: 1.563rem;
    margin: 1rem 0; }
    section.contact .content-wrapper .contact-wrapper img {
      max-width: 16px;
      margin-right: 15px; }
    section.contact .content-wrapper .contact-wrapper a {
      display: block; }
  section.contact .content-wrapper .image-wrapper {
    margin: 0 50px; }
    section.contact .content-wrapper .image-wrapper:after {
      content: "";
      display: block;
      padding-bottom: 31%; }
  section.subpage .content-wrapper .image-wrapper {
    margin-top: 1.75rem; }
    section.subpage .content-wrapper .image-wrapper:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
  section.subpage .content-wrapper .subheading {
    padding: 0 25px;
    margin-bottom: 1.75rem; }
  section.subpage .content-wrapper ul {
    margin: 0;
    padding: 0 25px;
    margin-bottom: 1.75rem; }
    section.subpage .content-wrapper ul li {
      line-height: 1.2; }
      section.subpage .content-wrapper ul li span {
        position: relative; }
        section.subpage .content-wrapper ul li span:first-of-type {
          padding-left: 25px; }
          section.subpage .content-wrapper ul li span:first-of-type:after {
            content: "";
            position: absolute;
            width: 0.333em;
            height: 0.333em;
            border-radius: 50%;
            background: #ea583f;
            top: 50%;
            left: 0px;
            transform: translate(0, -50%); }
  section.subpage .content-wrapper .swiper-pagination {
    bottom: 0; }
  section.subpage .swiper-wrapper {
    margin-bottom: 10px; }
  section .content-wrapper .image-wrapper {
    margin-bottom: 1.75rem;
    position: relative; }
    section .content-wrapper .image-wrapper:after {
      content: "";
      display: block;
      padding-bottom: 51.85%; }
  section .content-wrapper .partner-wrapper {
    padding: 0 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 85%;
    margin-bottom: 80px; }
    section .content-wrapper .partner-wrapper .partner {
      flex: 0 0 29.3333%;
      max-width: 29.3333%;
      transition: all 0.3s ease;
      padding-right: 15px; }
      section .content-wrapper .partner-wrapper .partner:hover {
        transition: all 0.3s ease; }
      section .content-wrapper .partner-wrapper .partner img {
        width: 100%;
        max-width: 100px; }
  section .content-wrapper .offer-wrapper {
    padding: 0 25px; }
    section .content-wrapper .offer-wrapper li {
      margin-bottom: 1.25em; }
      section .content-wrapper .offer-wrapper li .paragraph {
        padding: 1.25em 0; }
  section .content-wrapper .paragraph {
    padding: 0 25px; }
    section .content-wrapper .paragraph:last-of-type {
      margin-bottom: 0; }
  section .content-wrapper .col {
    flex: 0 0 50%;
    max-width: 50%; }
  section.gallery .swiper-container, section.certificates .swiper-container {
    padding: 25px 0; }
    @media (min-width: 1200px) {
      section.gallery .swiper-container, section.certificates .swiper-container {
        width: 50%; } }
    section.gallery .swiper-container .swiper-slide, section.certificates .swiper-container .swiper-slide {
      position: relative; }
      section.gallery .swiper-container .swiper-slide img, section.certificates .swiper-container .swiper-slide img {
        width: 100%; }
    section.gallery .swiper-container:nth-of-type(1) .swiper-slide, section.certificates .swiper-container:nth-of-type(1) .swiper-slide {
      align-self: center; }
      section.gallery .swiper-container:nth-of-type(1) .swiper-slide.mobile, section.certificates .swiper-container:nth-of-type(1) .swiper-slide.mobile {
        width: 65% !important; }
      section.gallery .swiper-container:nth-of-type(1) .swiper-slide.swiper-slide-active, section.certificates .swiper-container:nth-of-type(1) .swiper-slide.swiper-slide-active {
        border: 2px solid #ea583f; }

.gallery-wrapper .swiper-container {
  padding: 25px 0; }

.gallery-thumbs-wrapper {
  width: 50%;
  margin: auto;
  display: none; }
  .gallery-thumbs-wrapper .swiper-slide {
    pointer-events: auto !important; }
  .gallery-thumbs-wrapper img {
    width: 100%; }

.swiper-container {
  width: 100%; }

.button {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  border: 2px solid #ea583f;
  padding: 1.25rem;
  border-radius: 3.125rem;
  white-space: nowrap;
  display: flex;
  align-items: center; }
  .button:hover {
    border: 2px solid #ea583f; }
    .button:hover .button-chevron {
      -webkit-animation-play-state: paused;
              animation-play-state: paused;
      -webkit-animation-name: none;
              animation-name: none; }

.button-chevron {
  border: 2px solid #154fa0;
  width: 0.45rem;
  border-right-width: 0;
  border-bottom-width: 0;
  margin-left: 0.25rem;
  transform: rotate(135deg);
  -webkit-animation-name: chevron;
          animation-name: chevron;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
          animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }
  .button-chevron:after {
    content: "";
    display: block;
    padding-bottom: 100%; }
  .button-chevron.reversed {
    transform: rotate(-45deg);
    -webkit-animation-name: chevron-reversed;
            animation-name: chevron-reversed; }

.floating-icon {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 4.25em;
  height: 4.25em;
  border-radius: 50%;
  border: 5px solid #154fa0;
  background: white;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  z-index: 1000; }
  .floating-icon button {
    width: 100%;
    height: 100%; }
  .floating-icon img,
  .floating-icon svg {
    width: 60%;
    fill: #154fa0; }

@media (max-width: 480px) {
  header .main-menu-wrapper.hamb-wrapper .hamb-menu-wrapper {
    font-size: 5.5vw; }
  .hamb {
    width: 10vw;
    height: 8.8vw;
    max-height: 48px;
    max-width: 55px; }
  .heading {
    font-size: 9.26vw;
    margin-bottom: 5.5vw; }
    .heading:before {
      content: "";
      top: 5vw; }
  .subheading {
    font-size: 5.5vw; }
  ul {
    font-size: 4vw; }
  .paragraph {
    font-size: 4vw;
    margin-bottom: 5.5vw; }
  section {
    margin-bottom: 80px; }
    section.subpage .content-wrapper .subheading {
      margin-bottom: 4vw; }
    section.subpage .content-wrapper ul li span:first-of-type {
      padding-left: 4.6vw; }
    section.contact .content-wrapper .contact-wrapper {
      font-size: 4.6vw; }
  footer {
    font-size: 4vw; }
  .button {
    font-size: 4vw;
    padding: 4.63vw; } }

@media (min-width: 1200px) {
  .left-animation,
  .right-animation {
    opacity: 0; }
  header,
  footer {
    height: 110px;
    font-size: 1rem; }
    header .main-menu-wrapper .main-menu li ul,
    footer .main-menu-wrapper .main-menu li ul {
      display: flex;
      position: absolute;
      top: 0;
      flex-direction: row;
      left: 50%;
      white-space: nowrap;
      transform: translate(-50%, 30px); }
  footer {
    padding: 0 25px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%; }
    footer .footer-tel-wrapper {
      width: auto;
      background: white;
      color: #154fa0; }
    footer .social-media-wrapper {
      width: auto;
      flex-wrap: nowrap;
      margin: 0; }
      footer .social-media-wrapper span {
        margin-right: 10px; }
      footer .social-media-wrapper ul {
        width: auto; }
        footer .social-media-wrapper ul li {
          max-width: 24px; }
  .bar-1,
  .bar-2 {
    position: absolute;
    width: 0%;
    top: 0%;
    height: 100%;
    background: #d3e6f7; }
  .heading {
    position: relative;
    margin-top: 2.219rem;
    display: inline-block; }
  .app-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh; }
    .app-wrapper > .swiper-container {
      margin-top: 110px;
      height: calc(100% - 110px) !important; }
  section {
    overflow: hidden;
    margin-bottom: 0; }
    section .row {
      height: 100% !important;
      width: calc(100% - 8vw) !important;
      margin: 0 auto; }
    section .heading::before {
      width: calc(25px + 4vw); }
    section .content-wrapper {
      height: 100%; }
      section .content-wrapper .image-wrapper {
        height: 100%;
        margin-bottom: 0; }
      section .content-wrapper .partner-wrapper {
        max-width: 70%; }
        section .content-wrapper .partner-wrapper .partner {
          transition: all 0.3s ease;
          opacity: 0.5; }
          section .content-wrapper .partner-wrapper .partner:hover {
            opacity: 1;
            transition: all 0.3s ease; }
    section.about .content-wrapper .image-wrapper, section.offer .content-wrapper .image-wrapper {
      max-height: 80%; }
    section.about .left-animation, section.offer .left-animation {
      padding-right: 15%; }
    section.homepage {
      width: 100% !important; }
      section.homepage .content-wrapper .wrapper {
        width: 45%;
        position: absolute;
        left: 4vw;
        top: 0; }
        section.homepage .content-wrapper .wrapper .heading {
          position: relative;
          left: auto;
          top: auto; }
        section.homepage .content-wrapper .wrapper .paragraph {
          margin-left: 75px;
          color: white; }
    section.gallery, section.certificates {
      width: 100% !important; }
      section.gallery .heading, section.certificates .heading {
        position: absolute;
        z-index: 10;
        left: 4vw; }
      section.gallery .content-wrapper .gallery-wrapper, section.certificates .content-wrapper .gallery-wrapper {
        height: 85%; }
        section.gallery .content-wrapper .gallery-wrapper .swiper-slide.swiper-slide-active, section.certificates .content-wrapper .gallery-wrapper .swiper-slide.swiper-slide-active {
          border: none; }
      section.gallery .content-wrapper .swiper-container, section.certificates .content-wrapper .swiper-container {
        height: 100%;
        padding: 0; }
      section.gallery .content-wrapper .swiper-slide, section.certificates .content-wrapper .swiper-slide {
        height: 100%; }
        section.gallery .content-wrapper .swiper-slide.swiper-slide-active, section.certificates .content-wrapper .swiper-slide.swiper-slide-active {
          border: none; }
        section.gallery .content-wrapper .swiper-slide.swiper-slide-thumb-active, section.certificates .content-wrapper .swiper-slide.swiper-slide-thumb-active {
          border: 2px solid #ea583f; }
      section.gallery .content-wrapper .gallery-thumbs-wrapper, section.certificates .content-wrapper .gallery-thumbs-wrapper {
        height: 15%;
        width: 100%; }
        section.gallery .content-wrapper .gallery-thumbs-wrapper .swiper-container, section.certificates .content-wrapper .gallery-thumbs-wrapper .swiper-container {
          width: 50%; }
          section.gallery .content-wrapper .gallery-thumbs-wrapper .swiper-container .swiper-slide, section.certificates .content-wrapper .gallery-thumbs-wrapper .swiper-container .swiper-slide {
            height: 80%; }
    section.certificates .swiper-container .swiper-slide {
      text-align: center; }
      section.certificates .swiper-container .swiper-slide img {
        width: auto;
        height: 100%; }
    section.certificates .gallery-thumbs-wrapper {
      height: 10%; }
    section.subpage .content-wrapper .subheading {
      padding-left: 0; }
    section.subpage .content-wrapper .image-wrapper {
      margin-top: 0;
      max-width: 80%;
      margin: 0 auto; }
    section.subpage .left-animation {
      padding-left: 5rem; }
    section.subpage .swiper-container {
      height: 100%; }
  .gallery-thumbs-wrapper {
    display: block; }
  .swiper-slide-active {
    z-index: 10; }
  .button {
    padding: 1rem;
    font-size: 0.8rem; } }

@media (min-width: 1920px) {
  html {
    font-size: 18px; } }
  @media screen and (min-width: 1920px) and (min-width: 1920px) {
    html {
      font-size: calc( 18px + 7 * ((100vw - 1920px) / 1080)); } }
  @media screen and (min-width: 1920px) and (min-width: 3000px) {
    html {
      font-size: 25px; } }

@media (min-width: 1920px) {
  header .main-menu-wrapper .main-menu li ul {
    top: 5px; } }

.swiper-pagination:after,
.swiper-pagination-nested:after {
  content: "";
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 1px;
  height: 80%;
  transform: translate(-50%, 100%);
  background: #154fa0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }

.swiper-pagination .swiper-pagination-bullet,
.swiper-pagination-nested .swiper-pagination-bullet {
  outline: none;
  border: 1px solid #154fa0;
  border-radius: 50%;
  background: transparent;
  opacity: 1;
  width: 14px;
  position: relative;
  height: 14px; }
  .swiper-pagination .swiper-pagination-bullet:after,
  .swiper-pagination-nested .swiper-pagination-bullet:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    transform: translate(-50%, -50%) scale(0);
    background: #ef7544;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-radius: 50%; }
  .swiper-pagination .swiper-pagination-bullet-active:after,
  .swiper-pagination-nested .swiper-pagination-bullet-active:after {
    transform: translate(-50%, -50%) scale(1);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }

.swiper-pagination-nested {
  position: absolute;
  text-align: center;
  z-index: 100;
  width: auto !important;
  left: 50% !important;
  transform: translate(-50%, 0); }
  .swiper-pagination-nested:after {
    display: none; }

.loading-screen {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #d2e5f6;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001; }
  .loading-screen svg {
    width: 100%; }

.offer-header-wrapper {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start; }
  .offer-header-wrapper .arrow {
    transform: rotate(180deg);
    transition: all 0.3s ease;
    width: 100%;
    max-width: 20px; }
    .offer-header-wrapper .arrow.rotated {
      transform: rotate(0deg);
      transition: all 0.3s ease; }

